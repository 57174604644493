const getReturnValues = (countDown) => {
  // calculate time left
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const twoDigitMinutes = String(minutes).padStart(2, "0");
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);
  const twoDigitSeconds = String(seconds).padStart(2, "0");

  return [twoDigitMinutes, twoDigitSeconds];
};

export { getReturnValues };
