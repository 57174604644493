import React, { useEffect, useState } from "react";
import "./styles.css";
import { CountdownSettings } from "./countdownSettings";
import { Display } from "./timerDisplay";
import { useTimer } from "./usetimer";
import { getReturnValues } from "./formatting";

export default function App() {
  // countdownsettings
  const [breakLength, setBreakLength] = useState(5);
  const [sessionLength, setSessionLength] = useState(25);
  const [countingDown, setCountingDown] = useState(false);
  const [sessionIsCountingDown, setSessionIsCountingDown] = useState(true);
  var timelength = sessionIsCountingDown ? sessionLength : breakLength;
  const { playpause, reset, running, secondsElapsed, switchsession } = useTimer(
    timelength
  );
  const timeLeft = timelength * 60 - secondsElapsed;
  const [minutes, seconds] = getReturnValues(timeLeft * 1000);

  const switchSession = () => {
    beep();
    setSessionIsCountingDown(!sessionIsCountingDown);
    timelength = sessionIsCountingDown ? sessionLength : breakLength;
    switchsession();
  };
  useEffect(() => {
    timeLeft === 0 && countingDown && switchSession();
  }, [timeLeft]);

  useEffect(() => {
    setCountingDown(running);
  }, [running]);

  const resetTimer = () => {
    reset();
    setSessionIsCountingDown(true);
  };

  const beep = () => {
    const audio = document.getElementById("beep");
    audio.currentTime = 0;
    audio.play();
  };

  return (
    <div className="App">
      <h1>25 + 5 Clock</h1>
      <CountdownSettings
        breakLength={breakLength}
        setBreakLength={setBreakLength}
        sessionLength={sessionLength}
        setSessionLength={setSessionLength}
      />
      <Display
        minutes={minutes}
        seconds={seconds}
        countingDown={countingDown}
        sessionIsCountingDown={sessionIsCountingDown}
      />
      <div id="controls">
        <button id="start-stop" onClick={playpause}>
          ⏯
        </button>
        <button id="reset" onClick={resetTimer}>
          ↺
        </button>
      </div>
      <audio
        src="https://res.cloudinary.com/dtjgfcjyz/video/upload/v1657135691/beep_erkusc.mp3"
        className="beep"
        id="beep"
      />
    </div>
  );
}
