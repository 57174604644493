import { useCallback, useState } from "react";
import { interval } from "./interval";

const use1Second = interval(1e3);

export const useTimer = (sessionLength, breakLength) => {
  const [secondsElapsed, setSecondsElapsed] = useState(0);
  const [running, setRunning] = useState(false);

  const tick = useCallback(
    () =>
      running
        ? setSecondsElapsed((secondsElapsed) => secondsElapsed + 1)
        : undefined,
    [running]
  );

  const playpause = () => setRunning(!running);
  const switchsession = () => {
    setSecondsElapsed(0);
  };
  const reset = () => {
    setRunning(false);
    setSecondsElapsed(0);
  };

  use1Second(tick);

  return { playpause, reset, running, secondsElapsed, switchsession };
};
