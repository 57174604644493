import React, { useEffect, useState } from "react";

const CountdownSettings = (props) => {
  const {
    breakLength,
    setBreakLength,
    sessionLength,
    setSessionLength
  } = props;

  const incrementSession = () => {
    sessionLength < 60 && setSessionLength(eval(sessionLength + 1));
  };
  const incrementBreak = () => {
    setBreakLength(eval(breakLength + 1));
  };
  const decrementSession = () => {
    sessionLength > 5 && setSessionLength(eval(sessionLength - 1));
  };
  const decrementBreak = () => {
    breakLength > 1 && setBreakLength(eval(breakLength - 1));
  };

  return (
    <>
      <div className="session_break_wrapper">
        <div id="session">
          <p id="session-label">Session</p>
          <button onClick={() => incrementSession()} id="session-increment">
            ↑
          </button>
          <div id="session-length">{sessionLength} mins</div>
          <button onClick={() => decrementSession()} id="session-decrement">
            ↓
          </button>
        </div>

        <div id="break">
          <p id="break-label">Break</p>
          <button onClick={() => incrementBreak()} id="break-increment">
            ↑
          </button>
          <div id="break-length">{breakLength} mins</div>
          <button onClick={() => decrementBreak()} id="break-decrement">
            ↓
          </button>
        </div>
      </div>
    </>
  );
};

export { CountdownSettings };
