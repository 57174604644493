import React from "react";

const Display = ({ minutes, seconds, countingDown, sessionIsCountingDown }) => {
  const timer_class = countingDown ? "playing_timer" : "paused_timer";
  const timer_label_class = countingDown
    ? sessionIsCountingDown
      ? "SESSION"
      : "BREAK"
    : "PAUSED";
  return (
    <>
      <div id="timer" className={timer_class}>
        <div id="timer-label">{timer_label_class}</div>
        <div id="time-left">
          {minutes}:{seconds}
        </div>
      </div>
    </>
  );
};

export { Display };
